<template>
  <div class="photowalk-tab">
    <div ref="photowalkSequence" class="photowalkSequence">
      <div v-for="child in selectedPW.data" :key="child.id">
        <div
          :class="['photowalk-item']"
          v-if="child.children[0] && child.children[0].children.length"
        >
          <img
            :src="
              'https://api.clay.work/download/' +
              getImage(child) +
              '?Tenant=clay&qualifier=presentation'
            "
            :id="getId(child)"
          />
          <div class="photo-info">
            <eyecircle-icon class="show-detail" :size="14" />
            <div class="pos">{{getPos(child)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<style>
.photowalk-tab .card {
  width: 100%;
  overflow: auto;
}
.card-body {
  padding: 0px;
}
.photowalkSequence {
  width: 100%;
  overflow: auto;
  height: 85vh;
}

.photowalkSequence img {
  width: 100%;
  padding: 1vh;
}
.photowalk-item {
  display: flex;
  position: relative;
  color: #333;
}
.photo-info {
  font-size: var(--fsize-filterpanel);
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: white;
  text-align: right;
}
.photo-info .show-detail:hover {
  color: black;
}
.photo-info .show-detail:hover + .pos {
  display: block;
}
.photo-info .pos {
  text-align: left;
  display: none;
}
</style>


<script>
export default {
  name: "PhotowalkTab",
  props: {
    selectedPW: {
      type: Object,
      default: null,
    },
  },
  components: {},
  created() {},
  computed: {},
  methods: {
    getImage: (child) => {
      // console.log(child);
      const ch = child.children[0].children.filter(
        (ch) => ch.element.content.data?.value?.href
      );
      return ch[0] ? ch[0].element.content.data.value.href : null;
    },
    getId: (child) => {
      // console.log(child);
      return child.children[0].children[1]?.element.id.data.valueId;
    },
    getPos: (child) => {
      // console.log(child);
      const pos = child.children[0].children.filter(
        (ch) => ch.element.content.data.attribute === "geolocation"
      );
      if (pos) {
        return pos.longitude + ", " + pos.latitude;
      } else {
        return pos;
      }
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
</style>