<template>
  <div class="side-panel" @keydown.esc="toggleFullscreen">
    <transition name="slide">
      <b-card>
        <knowledge-tab />
        <new-photowalk-tab
          v-if="$store.getters.activeDetailsTab === 'newphotowalk'"
          :cId="cId"
        />
        <photowalk-tab
          v-if="$store.getters.activeDetailsTab === 'photowalk'"
          :selectedPW="selectedPW"
        />
      </b-card>
    </transition>
  </div>
</template>

<script>
import KnowledgeTab from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/KnowledgeTab.vue";
import NewPhotowalkTab from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/media/NewPhotowalkTab.vue";
import PhotowalkTab from "@/components/demo/projectarea/overview/map/mapmodules/knowledge/media/PhotowalkTab.vue";
import { EventBus } from "@/components/EventBus.js";

export default {
  props: {
    cId: {
      type: String,
      default: null,
    },
  },
  methods: {
    toggleSplitView() {
      this.$store.commit("toggleSplitView");
    },
    toggleFullscreen() {
      this.$store.state.view = "detailPanelHidden";
    },
    showDetailHandler(detailTab, selected) {
      this.$store.commit("setActiveDetailsTab", detailTab);
      this.selectedPW = selected;
    },
  },
  data() {
    return {
      isFilterPanelOpen() {
        return this.$store.getters.isFilterPanelOpen;
      },
      tabs: { asset: 0, protocol: 1, instance: 2, photowalk: 1 },
      tabNames: ["asset", "protocol", "instance"],
      selectedPW: null,
    };
  },
  computed: {
    active: {
      get: function () {
        return this.tabs[this.$store.getters.activeDetailsTab];
      },
      set: function (v) {
        this.$store.commit("setActiveDetailsTab", this.tabNames[v]);
      },
    },
    getIconSize() {
      return document.documentElement.clientHeight * 0.05;
    },
  },
  components: {
    KnowledgeTab,
    NewPhotowalkTab,
    PhotowalkTab,
  },
  created() {
    EventBus.$on("showDetail", this.showDetailHandler);
    EventBus.$on("activeContextChanged", () => {
      this.selectedPW = null;
    });
    EventBus.$on("projectSelected", () => {
      this.selectedPW = null;
    });
  },
  destroyed() {
    EventBus.$off("showDetail", this.showDetailHandler);
  },
};
</script>

<style scoped>
.push-right {
  margin-left: auto;
  float: right;
}
/* .right-controls .nav-link {
  padding: 0vh 0.05vw;
  border: 0px;
} */

.right-controls .active.material-design-icon {
  background: var(--primary);
  color: #fff;
}
.title .material-design-icon {
  line-height: calc(var(--graphicarea-ht) - 2vh);
  padding: 1vh 1vh;
  display: inline-block;
}
.right-controls .nav-link .material-design-icon {
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  line-height: var(--graphicarea-ht);
  width: var(--graphicarea-ht);
}
</style>

<style>
.tab-pane {
  height: calc(var(--content-ht) - var(--detailpanel-header-ht));
  position: relative;
  background: #f7f7f7;
}
.right-half .card-header-tabs {
  margin-right: 0px;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  background-color: #333333;
}

.right-half li.nav-item {
  width: calc((50vw - (2 * var(--graphicarea-ht))) / 3);
  font-weight: 600;
}

.right-half li.nav-item.content-header-icon {
  width: var(--graphicarea-ht);
}

.right-half li.nav-item .nav-link {
  font-size: var(--fsize-filterpanel);
  color: white;
  padding: 0;
}
.right-half li.nav-item .nav-link.active {
  background-color: var(--primary);
}
.right-half li.nav-item .nav-link:hover {
  border: none;
  font-weight: 600;
}
</style>