<template>
  <div class="knowledge">
    <b-toast id="error" title="No write permissions" :solid="true">
      Please request admin team to allow access.
    </b-toast>
    <b-card no-body>
      <b-container
        v-if="entitySelected"
        class="knowledge-detail"
        ref="knowledgeDetail"
        :class="{ editMode: isEditMode === true }"
      >
        <b-row>
          <div :class="['title', getKnowledgeType]">
            <!-- <pencil-icon  :size="getHeaderIconSize" /> -->

            <pf-narratives-filter-icon
              :size="getHeaderIconSize"
              v-if="getKnowledgeType === 'narrative'"
              fillColor="#fff"
              className="content-header-title-icon"
            />
            <pf-forces-filter-icon
              :size="getHeaderIconSize"
              v-if="getKnowledgeType === 'force'"
              fillColor="#fff"
              className="content-header-title-icon"
            />
            <pf-resolutions-filter-icon
              :size="getHeaderIconSize"
              v-if="getKnowledgeType === 'resolution'"
              fillColor="#fff"
              className="content-header-title-icon"
            />
            <span class="title-text">&nbsp;&nbsp;{{ getContentTitle }}</span>
          </div>
        </b-row>

        <b-row v-for="(e, i) in getContentElements" :key="i">
          <b-col cols="12" class="p-0" v-if="e.type === 'image'">
            <div class="">
              <img
                :src="
                  'https://api.clay.work/download/' +
                  e.value +
                  '?Tenant=clay&qualifier=presentation'
                "
              />
            </div>
          </b-col>
          <b-col cols="8" offset="2" class="p-0" v-if="e.type === 'text'">
            <div>
              <div class="description" v-html="markup(e.value)"></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-if="isEditMode">
        <b-row>
          <div class="title">
            <!-- <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2"> -->
            <b-dropdown id="dropdown-1">
              <template #button-content>
                <pf-narratives-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'narrative'"
                />
                <pf-forces-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'force'"
                />
                <pf-practices-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'practice'"
                />
                <pf-protocols-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'protocol'"
                />
                <pf-resolutions-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'resolution'"
                />
                <pf-capabilities-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'capability'"
                />
                <pf-formats-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
                  v-if="type === 'format'"
                />
              </template>
              <b-dropdown-item @click="setEntityType('force')"
                ><pf-forces-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#9c3"
              /></b-dropdown-item>

              <b-dropdown-item @click="setEntityType('narrative')"
                ><pf-narratives-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
              <b-dropdown-item @click="setEntityType('practice')"
                ><pf-practices-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
              <b-dropdown-item @click="setEntityType('protocol')"
                ><pf-protocols-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
              <b-dropdown-item @click="setEntityType('resolution')"
                ><pf-resolutions-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
              <b-dropdown-item @click="setEntityType('capability')"
                ><pf-capabilities-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
              <b-dropdown-item @click="setEntityType('format')"
                ><pf-formats-filter-icon
                  :size="getHeaderIconSize"
                  fillColor="#fff"
                  className="content-header-title-icon"
              /></b-dropdown-item>
            </b-dropdown>
            <span @dblclick="editTitle" :class="{ hide: isTitleEditable }"
              >&nbsp;&nbsp;{{ newTitle }}</span
            >
            <input
              :class="{ show: isTitleEditable }"
              type="text"
              @keyup="(e) => keyup(e)"
            />
            <check-icon
              class="push-right"
              :size="getHeaderIconSize"
              :class="{ show: isTitleEditable, disabled: !isSaveActive }"
              @click="save"
            />
          </div>
        </b-row>
        <!-- <b-row>
          <b-col cols="12" class="p-0">
            <div class="pattern-protocol">
              <textarea
                class="input-desc"
                placeholder="Text protocol"
                @change="(e) => inputChanged(e)"
              />
            </div>
          </b-col>
        </b-row> -->
      </b-container>
    </b-card>
  </div>
</template>

<style>
#map-area > .card {
  background-color: #e9e9e9;
}

.card-header {
  padding: 0px;
  height: var(--graphicarea-ht);
}

.card-header-tabs {
  margin-bottom: 0;
  flex-flow: nowrap;
}

.card-body {
  padding: 0px;
}

.tab-content {
  height: var(--content-ht);
}

.nav-link {
  padding: 0vh 1.5vw;
  line-height: var(--graphicarea-ht);
  height: var(--graphicarea-ht);
}

.push-right {
  margin-left: auto;
  float: right;
}

.right-controls .active.material-design-icon {
  background: var(--primary);
  color: #fff;
}
.title .material-design-icon {
  line-height: calc(var(--graphicarea-ht) - 2vh);
  padding: 1vh 1vh;
  display: inline-block;
}
.title.narrative {
  color: white;
  background-color: #3cf;
}
.title.force {
  color: white;
  background-color: #fd0;
}
.title.resolution {
  color: white;
  background-color: #9c3;
}
.title .title-text {
  vertical-align: baseline;
  line-height: var(--ktab-header-ht);
}
.right-controls .nav-link .material-design-icon {
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  line-height: var(--graphicarea-ht);
  width: var(--graphicarea-ht);
}
.card-header-tabs {
  margin-right: 0px;
}
.left-margin .material-design-icon {
  pointer-events: none;
  padding: 1vh 1vw;
}
.tile .material-design-icon {
  pointer-events: none;
  padding: 0;
  line-height: 4vh;
}
</style>


<script>
import { EventBus } from "@/components/EventBus.js";

export default {
  name: "knowledge-tab",
  components: {},
  created() {
    EventBus.$on("changeSelectedKnowledge", () => {
      if (this.$refs.knowledgeDetail) this.$refs.knowledgeDetail.scrollTop = 0;
    });
    EventBus.$on("activeContextChanged", () => {
      this.$store.commit("setSelectedKnowledge", null);
    });
    EventBus.$on("projectSelected", () => {
      this.$store.commit("setSelectedKnowledge", null);
    });
  },
  computed: {
    getContentTitle() {
      return this.$store.getters.knowledgeName;
    },
    getKnowledgeType() {
      return this.$store.getters.knowledgeType;
    },
    entitySelected() {
      const p = this.$store.getters.knowledge;
      return p && Object.keys(p).length > 0;
    },
    getContentElements() {
      return this.$store.getters.contentElements;
    },
    getIconSize() {
      return document.documentElement.clientHeight * 0.05;
    },
    getHeaderIconSize() {
      return document.documentElement.clientHeight * 0.03;
    },
    isEditMode() {
      const v = this.$store.getters.detailTabMode;
      return v === "edit";
    },
    nextEmptyHexCell() {
      const n = this.$store.getters.nextEmptyHexCell;
      return n;
    },
  },
  methods: {
    toggleSplitView() {
      this.$store.commit("toggleSplitView");
      EventBus.$emit("viewChange");
    },
    editTitle() {
      this.isTitleEditable = true;
    },
    keyup(e) {
      if (e.keyCode === 27) {
        this.isTitleEditable = false;
        this.isSaveActive = false;
      } else if (e.keyCode === 13) {
        this.isTitleEditable = false;
        this.newTitle = e.target.value;
        this.isSaveActive = false;
      } else {
        this.newTitle = e.target.value;
        this.isSaveActive = true;
      }
    },
    inputChanged(e) {
      this.newTextContent = e.target.value;
      this.isSaveActive = true;
    },
    setEntityType(type) {
      this.type = type;
    },
    markup(content) {
      if (content.indexOf("###") === 0) {
        content =
          "<p class='card-text hash3'>" +
          content.substring(3, content.length) +
          "</p>";
      }
      content = "<p class='card-text regular'>".concat(content).concat("</p>");
      let regularTags = {
        0: "</p><p class='card-text regular'>",
        1: "</p><p class='card-text regular'>",
        next: 1,
      };
      content = content.replaceAll("\n\n", () => {
        regularTags.next = (regularTags.next + 1) % 2;
        return regularTags[regularTags.next];
      });
      let boldTags = {
        0: "</span><strong>",
        1: "</strong><span class='card-text regular'>",
        next: 1,
      };
      content = content.replaceAll("**", () => {
        boldTags.next = (boldTags.next + 1) % 2;
        return boldTags[boldTags.next];
      });
      let emTags = { 0: "</em><em>", 1: "</em><em>", next: 1 };
      content = content.replaceAll("_", () => {
        emTags.next = (emTags.next + 1) % 2;
        return emTags[emTags.next];
      });
      content = content.replaceAll("<em></p>", "</p>");
      let ulistTags = { 0: "</li><li>", 1: "</li><li>", next: 1 };
      content = content.replaceAll("*", () => {
        ulistTags.next = (ulistTags.next + 1) % 2;
        return ulistTags[ulistTags.next];
      });
      return content;
    },
  },
  data: function () {
    return {
      isTitleEditable: false,
      newTitle: "New Title",
      newTextContent: "",
      isSaveActive: false,
      // api: new Api(),
      type: "force",
    };
  },
  destroyed() {
    EventBus.$off("changeSelectedKnowledge");
  },
};
</script>

<style scoped>
.knowledge {
  color: #666;
  font-size: var(--fsize-graphicarea);
  overflow: auto;
}
.input-desc {
  width: calc(100% - 50px);
  height: 60%;
  margin-left: 50px;
  border-radius: 0px;
}
.title {
  width: 100%;
  line-height: var(--ktab-header-ht);
  padding: 0;
  font-weight: 1000;
  font-size: 1.3em;
}
.title input {
  display: none;
  height: var(--ktab-header-ht);
}
.title .hide {
  display: none;
}
.title input.show {
  display: inline;
}

.title .check-icon {
  display: none;
  color: white;
}
.title .check-icon.show {
  display: inline;
}
.title .check-icon.disabled {
  cursor: none;
  color: #aaa;
}
.knowledge-detail {
  height: calc(var(--content-ht) - var(--graphicarea-ht) - 1px);
  overflow: auto;
}

.knowledge img {
  width: 100%;
}

.knowledge .description {
  padding: 5vh 1vw;
}

.knowledge-detail .description span.bold {
  font-weight: 600;
  font-size: 12px;
}

.left-margin li {
  width: 100%;
  text-align: center;
  padding: 0.2vh 0;
}

.left-margin ul {
  padding: 1vh 0;
}

.tiles {
  max-height: calc(var(--content-ht) - var(--graphicarea-ht) - 1vh);
  overflow: auto;
}

.tile {
  font-size: var(--fsize-footer);
  padding: 0.5vh;
  margin-bottom: 2px;
  line-height: 1.6vh;
  background: #fff;
  color: #999;
  border: 1px solid #fff;
}
.tile.red {
  background: #eb4e29;
  color: #fff;
}
.tile.green {
  background: var(--primary);
  color: #fff;
}
.tile.blue {
  background: #33ccff;
  color: #fff;
}
.tile div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.knowledge ul.dropdown-menu {
  min-width: 50px;
  width: 50px;
  padding-top: 0;
  left: -5px !important;
  top: -2px !important;
}
.knowledge .dropdown-item {
  padding: 0.25rem;
  text-align: center;
}
.knowledge .dropdown-toggle {
  padding: 0;
  background-color: var(--primary) !important;
  width: 50px;
}

.knowledge .content-header-title-icon {
  border-right: none;
}
.content-header-title-icon {
  width: 5vh;
  line-height: 5vh;
  border-right: solid 1px white;
  display: inline-block;
  text-align: center;
}
.b-toast {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 50vw;
  top: 10vh;
  left: 25vw;
}
</style>